<template>
  <scoped-job-list title="My Bookmarked Jobs" scope="bookmarked" past-tense-action="bookmarked" />
</template>

<script>
import ScopedJobList from '~/components/Jobs/Freelancer/ScopedList'

export default {
  components: { ScopedJobList },
}
</script>
