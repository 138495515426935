<template>
  <dashboard>
    
    
    <el-row :gutter="15">
      <el-col :xs="24">
        <div class="jobfeed">
          <el-card class="job">
            <h4 class="mx-3 mt-3 mb-3 bold orange-text" v-text="title" />
            <hr>
            <div v-loading="! fetched" class="body">
              <div v-if="jobs.length > 0" class="">
                <freelancer-job-list :jobs="jobs" />
                <div v-if="totalResults > 10" class="job-pagination mx-auto mb-4">
                  <el-pagination
                    background
                    :current-page.sync="page"
                    layout="prev, pager, next"
                    :total="totalResults"
                  />
                </div>
              </div>
              <div v-else v-show="fetched" class="text-center mt-4 mb-4">
                <center>
                  <p class="small">You have not {{ pastTenseAction }} any jobs</p>
                  <router-link to="/freelancer/jobs">
                    <el-button type="success">Search for Jobs</el-button>
                  </router-link>
                </center>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import Job from '~/models/Jobs/FreelancerJob'
import FreelancerJobList from '~/components/Jobs/Freelancer/List'

export default {
  components: { Dashboard, FreelancerJobList },

  props: {
    title: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
    pastTenseAction: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      page: 1,
      jobs: [],
      totalResults: 0,
      fetched: false,
    }
  },

  watch: {
    page () {
      this.fetch()
    },
  },

  created () {
    this.fetch()
  },

  methods: {
    fetch () {
      Job[this.scope](this.page).then(({ data }) => {
        this.jobs = data.data
        this.totalResults = data.meta.total
        this.fetched = true
      })
    },
  },
}

</script>

<style lang="scss">
  .jobfeed {
    .search {
      padding: 30px;
    }
    .el-input-group__append button.el-button, .el-input-group__append, .el-input-group__prepend {
      color: #FFF;
      background-color: #E87722;
      border-color: #E87722;
    }
    .el-pagination {
      text-align: center;
    }
  }
@media (max-width: 767px) {
  .mb-xs-3 {
    margin-bottom: 1rem;
  }
}
</style>
